import * as React from "react"
import '../styles/global.css'
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';

// markup
const HomeFeatureView = ({icon, title, description}) => {

    library.add(fas)  
    
    return(
        <div className="home-features">
            <FontAwesomeIcon 
            icon={icon}
            className="service-icon"/>
            <h2 className="post-card-title">{title}</h2>
            <h3 className="post-card-excerpt">{description}</h3>
        </div>
    );
}

export default HomeFeatureView