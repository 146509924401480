import React, {useState, useEffect} from "react";
import { useIntl, changeLocale } from "gatsby-plugin-intl";
import { Link, graphql} from "gatsby";
import Layout from "../components/Layout";
//import "../styles/layout.css"
import HomeBlogView from "../components/HomeBlogView";
import HomeFeatureView from "../components/HomeFeatureView";
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fas } from '@fortawesome/free-solid-svg-icons';
import Carousel, { CarouselItem } from "../components/HomeCarousel/Carousel";
import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image';
import Count from "../components/HomeCounter/Counter";
import Seo from "../components/seo";
// import { ClientsData } from "../../static/constants";
// import { AutoplayCarousel } from "../components/HomeCarousel/Carousel";

const IndexPage = (props) => {
  
  const services = props.data.services.edges;
  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : "";

  const [postData, setPostData] = useState([]);

  useEffect(()=>{
    if(intl.locale !=="en"){
      changeLocale(intl.locale);
    }
    let postArray = [];
    switch(intl.locale){
      case "hy":
        props.data.articles.edges.map((edge) => {
          const tempHy = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleHy ?
              edge.node.titleHy.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            }
          postArray = [...postArray, tempHy]
        })
        setPostData(postArray);
        break;
      case "ru":
        props.data.articles.edges.map((edge) => {
          const tempRu = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleRu ?
              edge.node.titleRu.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            }
          postArray = [...postArray, tempRu]
        })
        setPostData(postArray);
        break;
      case "fa":
        props.data.articles.edges.map((edge) => {
          const tempFa = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleFa ?
              edge.node.titleFa.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            }
          postArray = [...postArray, tempFa]
        })
        setPostData(postArray);
        break;
      case "fr":
        props.data.articles.edges.map((edge) => {
          const tempFr = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.titleFr ?
              edge.node.titleFr.trim():
              edge.node.title.trim(),
            date: edge.node.date,
            }
          postArray = [...postArray, tempFr]
        })
        setPostData(postArray);
        break;
      default:
        props.data.articles.edges.map((edge) => {
          const tempEN = {
            id : edge.node.id,
            to: edge.node.slug,
            image: edge.node.heroImage,
            alt: edge.node.heroImageAlt,
            title : edge.node.title.trim(),
            date: edge.node.date,
          }
          postArray = [...postArray, tempEN]
        })
        setPostData(postArray);
        break;
    }
  },[
    intl.locale,
    props.data.articles.edges
  ])

  library.add(fas);
  
  const stylingName = (name) =>{  
    let finalClass = name;
    const rtl = intl.locale !== "fa" ? false : true ;
    if (rtl) {
      finalClass = `${name}RTL` ; 
    } 
    return finalClass; 
  }

  return (
      <Layout 
        isHome={true}>
        <div className={stylingName("container")}>
          <div className="post-feed">
              <HomeFeatureView
                icon="fa-solid fa-user-shield"
                title={intl.formatMessage({id: "Client First"})}
                description={intl.formatMessage({id: "Protecting Clients' interests and rights in the best possible way is the main priority for our team."})}
              />
              <HomeFeatureView
                icon="fa-solid fa-bolt-lightning"
                title={intl.formatMessage({id: "Quick as Possible"})}
                description={intl.formatMessage({id: "To deal with the existing daily challenges, we provide quick legal services meanwhile maintaining quality at the top level."})}
              />
              <HomeFeatureView
                icon="fa-solid fa-people-group"
                title={intl.formatMessage({id: "Team Work"})}
                description={intl.formatMessage({id: "Specialists from various law fields and backgrounds have been gathered in Gritarres in order to cover the whole legal and financial risks."})}
              />
    
          </div>

          <div className="home-statistic-box">
            <Count 
              label={intl.formatMessage({id:"Total Cases"})} 
              number="254" 
              duration="5"
            />
            <Count 
              label={intl.formatMessage({id:"Customers"})} 
              number="131" 
              duration="5"
            />
            <Count 
              label={intl.formatMessage({id:"Success Rate"})} 
              number="81" 
              duration="5"
              unit="%"
            />
            <Count 
              label={intl.formatMessage({id:"Fields of Law"})} 
              number="12" 
              duration="5"
            />
          </div>

          <section className="home-section">
          <h1>{intl.formatMessage({id:"Services"})}</h1>
            <div className="home-services-box">
              {
                services.map((edge) => (
                  <Link  
                  key={edge.node.id} 
                  className="post-card"
                  to={`${locale}/services/${edge.node.slug}`}>
                    <header className="post-card-header">
                      <FontAwesomeIcon 
                        icon={edge.node.icon}
                        className="service-icon"/>
                      <h2 className="post-card-title">{intl.formatMessage({id: `${edge.node.title}`})}</h2>
                    </header>
                    <h3 className="post-card-excerpt">{intl.formatMessage({id: `${edge.node.subtitle}`})}</h3>
                </Link>
                ))
              }
            </div>
            <div className="home-button">
              <Link to={`${locale}/services`}>{intl.formatMessage({id:"See all services"})}</Link> 
            </div>
          </section>
          {/* <section className="home-section">
            <h1>{intl.formatMessage({id:"Customers"})}</h1>
            <div className="home-customer-box">
              {props.data.clients.edges.map(edge=>{
                
                return(
                  <a id={edge.node.id} href={edge.node.href}>
                    <GatsbyImage
                      
                      image= {getImage(edge.node.logo)}
                      alt={edge.node.alt}
                    />
                  </a>
                )
              })}
            
            </div>
          </section> */}

          <section className="home-section">
            <h1>{intl.formatMessage({id:"Customers"})}</h1>
            <Carousel>
              {props.data.clients.edges.map(edge=>{
                return(
                  <CarouselItem>
                    <a href={edge.node.href}>
                      <GatsbyImage
                        image= {getImage(edge.node.logo)}
                        alt={edge.node.alt}
                      />
                    </a>
                  </CarouselItem>
                )
              })}
              {/* <CarouselItem>
                <a href="https://cisarmenia.com/">
                  <StaticImage 
                    src="../../static/img/customers/1.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>
              
              <CarouselItem>
                <a href="https://mystep.foundation/en/">
                  <StaticImage 
                    src="../../static/img/customers/2.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>
              
              <CarouselItem>
                <a href="https://gritarres.com/">
                  <StaticImage 
                    src="../../static/img/customers/3.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>
              
              <CarouselItem>
                <a href="https://gritarres.com/">
                  <StaticImage 
                    src="../../static/img/customers/4.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>

              <CarouselItem>
                <a href="https://www.facebook.com/SubashinArmenia/">
                  <StaticImage 
                    src="../../static/img/customers/9.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>

              <CarouselItem>
                <a href="https://www.gyumricity.am/">
                  <StaticImage 
                    src="../../static/img/customers/5.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>

              <CarouselItem>
                <a href="https://gritarres.com/">
                  <StaticImage 
                    src="../../static/img/customers/6.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>

              <CarouselItem>
                <a href="https://www.yerevanaudit.am/">
                  <StaticImage 
                    src="../../static/img/customers/7.png"
                    alt=""
                  >
                  </StaticImage>
                </a>
              </CarouselItem>

              <CarouselItem>
                <a href="https://gritarres.com/">
                  <StaticImage 
                    src="../../static/img/customers/8.png"
                    alt=""  
                  >
                  </StaticImage>
                </a>
              </CarouselItem> */}
            </Carousel>
          </section>
          {/* <section className="home-section">
            <h1>{intl.formatMessage({id:"Customers"})}</h1>
            <AutoplayCarousel/>           
          </section> */}

          <section className="home-section">
            <h1>{intl.formatMessage({id:"Recent articles"})}</h1>
            <HomeBlogView posts={postData}/>
            <div className="home-button">
            <Link to={`${locale}/blog`}>{intl.formatMessage({id:"See all articles"})}</Link> 
            </div>
          </section>

        </div>
      </Layout>
  );
}

export const Head = ({ pageContext }) => (
  <Seo
  title={`${pageContext.intl.messages["Home Page"]}` +" | "  + `${pageContext.intl.messages["Gritarres Law Firm"]}`}
  description={pageContext.intl.messages["At his best, man is the noblest of all animals; separated from law and justice he is the worst."]}
  article={false}>
  </Seo>
)

export const HomeArticlesQuery = graphql`
    query homePageQuery {
        articles: allContentfulBlogPost(sort: {fields: date, order: DESC}, limit: 3) {
          edges {
            node {
              id
              date
              slug
              title
              titleFa
        			titleFr
        			titleHy
        			titleRu
              heroImageAlt
              heroImage {
                gatsbyImageData
              }
              summary {
                summary
              }
            }
          }
        }
        services: allContentfulService(sort: {fields: priority, order: ASC}, limit: 4) {
          edges {
            node {
              title
              subtitle
              targetAudience
              icon
              id
              slug
            }
          }
        }
        clients: allContentfulClients(sort: {fields: priority, order: ASC}) {
          edges {
            node {
              alt
              id
              logo {
                gatsbyImageData
              }
              title
              href
            }
          }
        }
    }
    `

export default IndexPage


