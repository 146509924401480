import React, {useState, useEffect} from 'react';
import * as carouselStyle from './Carousel.module.css';
// import { cardDetails } from '../../../static/constants';
// import {GatsbyImage, getImage, StaticImage} from 'gatsby-plugin-image';


export const CarouselItem = ({children}) =>{
    return (
        <div className={carouselStyle.item}>
            {children}
        </div>
    )
}

const Carousel = ({children}) => {
    
    const [activeIndex, setActiveIndex] = useState(0)

    const updateIndex = (newIndex) =>{
        if(newIndex<0){
            newIndex=React.Children.count(children)-1;
        }else if(newIndex>= React.Children.count(children)){
            newIndex=0; 
        }
        setActiveIndex(newIndex); 
    }

    useEffect( ()=>{
        const interval = setInterval( ()=> {
            updateIndex(activeIndex + 1); 
        }, 5000) ; 
        return ()=> {
            if (interval){
                clearInterval(interval)
            }
        }
    })


    return(  
        <div className={carouselStyle.carousel}>
            <div className={carouselStyle.inner} style={{transform: `translateX(-${activeIndex*25}%)`}}>
                {React.Children.map(children, (child,index)=>{
                    return React.cloneElement(child)
                })
                }
            </div>
        </div>
    )
}

// export function AutoplayCarousel() {
//     return (
//       <div className={carouselStyle.carouselContainer}>
//         <div className={carouselStyle.carouselTrack}>
//           {Object.keys(cardDetails).map((detailKey) => {
//             console.log("imgURL:", cardDetails[detailKey].imgUrl)
//             console.log("imgTitle:", cardDetails[detailKey].title)
//             return (
//               <CarouselItemNew
//                 imgUrl={cardDetails[detailKey].imgUrl}
//                 imgTitle={cardDetails[detailKey].title}
//               ></CarouselItemNew>
//             );
//           })}
//           {Object.keys(cardDetails).map((detailKey) => {
//             return (
//               <CarouselItemNew
//                 imgUrl={cardDetails[detailKey].imgUrl}
//                 imgTitle={cardDetails[detailKey].title}
//               ></CarouselItemNew>
//             );
//           })}
//         </div>
//       </div>
//     );
//   }

//   export function CarouselItemNew({ imgUrl, imgTitle }) {
//     return (
//       <div className={carouselStyle.carouselCard}>
//         <img src={imgUrl} alt={imgTitle}></img>
//       </div>
//     );
//   }

export default Carousel