import React, { useEffect, useState } from 'react';
import * as countStyle from './Counter.module.css'

//styling


const Count = ({label, number, duration, unit}) => {
    // label of counter
  // number to increment to
  // duration of count in seconds


  // number displayed by component
  const [count, setCount] = useState("0")
  

  /* setInterval(() => {
      setRender(true)
  }, 10000);*/

  useEffect(() => {
    let start = 0;
    // first three numbers from props
    const end = parseInt(number.substring(0,3))
    // if zero, return
    if (start === end) return;

    // find duration per increment
    let totalMilSecDur = parseInt(duration);
    let incrementTime = (totalMilSecDur / end) * 1000;

    // timer increments start counter 
    // then updates count
    // ends if start reaches end
    let timer = setInterval(() => {
      start += 1;
      setCount(String(start) + number.substring(3))
      if (start === end) clearInterval(timer)       
    }, incrementTime);

    // dependency array
  }, [number, duration]);

  return (
    <div className={countStyle.Count}>
      <h3 className="home-features">
        <h2>{label}</h2>
        <h1>{count}{unit}</h1>
      </h3>
    </div>
  );
}

export default Count;