import * as React from "react"
import { Link } from "gatsby"
import { useIntl, FormattedDate } from "gatsby-plugin-intl"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import '../styles/global.css'

const HomeBlogView = ({posts}) => {

  const intl = useIntl()
  const locale = intl.locale !== "en" ? `/${intl.locale}` : "";

  return (
    <div className="home-article-box">
        <div className="post-feed">
        {posts.map((post) => (
            <article key={post.id}>
                <Link
                className="post-card" 
                to={`${locale}/blog/${post.to}`}>
                    <header className="post-card-header">
                    <GatsbyImage
                    className="post-card-image" 
                    image= {getImage(post.image)}
                    alt={post.alt}
                    />
                    <h2 className="post-card-title">{post.title}</h2>
                    </header>
                    <time>
                    <FormattedDate
                        className="post-card-excerpt"
                        value={new Date(post.date)}
                        year="numeric"
                        month="long"
                        day="2-digit"
                    />
                    </time>
                </Link>
            </article> ))}
        </div>
    </div>
  );
}

export default HomeBlogView